<template>
  <div class="bg-gray-200 min-h-screen flex flex-col justify-center items-center">
    <div class="bg-white rounded-lg shadow-lg p-8 min-w-96 max-w-md">
      <h1 class="text-4xl font-bold text-center text-blue-700 mb-8">手语视频采集</h1>
      <form class="space-y-6" @submit.prevent>
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="username">
            用户名
          </label>
          <input class="w-full px-4 py-2 rounded-lg border border-gray-400" id="username" name="username" type="text"
                 v-model="username">
        </div>
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="password">
            密码
          </label>
          <input class="w-full px-4 py-2 rounded-lg border border-gray-400" id="password" name="password"
                 type="password" v-model="password">
        </div>
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="confirmPassword">
            确认密码
          </label>
          <input class="w-full px-4 py-2 rounded-lg border border-gray-400" id="confirmPassword" name="confirmPassword"
                 type="password" v-model="confirmPassword">
        </div>
        <div>
          <div>
            <button class="w-full bg-blue-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded-lg"
                    @click="submitRegister">
              注册
            </button>
          </div>
          <div>
            <router-link to="/login" class="text-center block mt-4 text-blue-700 hover:text-purple-900">
              已经注册？
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits} from 'vue';
import { toast } from 'vue3-toastify';
const props = defineProps({
  register: Function
});

const emits = defineEmits(['submit']);

const username = ref('');
const password = ref('');
const confirmPassword = ref('');

const submitRegister = () => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  if (username.value.length < 4) {
    toast.error('用户名必须至少为4个字符');
  } else if (password.value !== confirmPassword.value) {
    toast.error('密码不匹配');
  } else if (!passwordRegex.test(password.value)) {
    toast.error('密码必须至少为8个字符，并包含至少一个大写字母、一个小写字母和一个数字');
  } else {
    emits('submit', {username: username.value, password: password.value});
  }
};
</script>

<style>
</style>