<template>
  <LoginWindow @submit="login" />
</template>

<script>
import axios from 'axios';
import LoginWindow from '../components/LoginWindow.vue'
import { toast } from 'vue3-toastify';

export default {
  components: {
    LoginWindow
  },
  data: () => ({
    username: '',
    password: ''
  }),
  methods: {
    login(credentials) {
      axios.post(import.meta.env.VITE_API_BASE_URL + 'api/users/login/', {
        username: credentials.username,
        password: credentials.password
      })
      .then(response => {
        sessionStorage.setItem('access_token', response.data.access_token);
        this.$router.push('/');
      })
      .catch(error => {
        toast.error('登录失败: ' + error.response.data.message);
      });
    }
  }
}
</script>