<template>
  <div class="flex flex-col items-center justify-center space-y-4">
    <select v-model="selectedCamera" class="border border-gray-400 rounded p-2">
      <option v-for="(camera, index) in cameras" :key="index" :value="camera.deviceId">{{ camera.label }}</option>
    </select>
    <div class="video-container items-center justify-center w-full">
      <video v-if="!hasRecorded" ref="video" autoplay class="border-4 border-blue-900 rounded-lg w-full"></video>
      <video v-else :src="videoURL" controls class="border-4 border-blue-900 rounded-lg w-full"></video>
    </div>
    <div class="flex flex-row items-center justify-center space-x-4">
      <button v-if="!isRecording" @click="startRecording" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">开始录制</button>
      <button v-else @click="stopRecording" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">结束录制</button>
      <button @click="cancelRecording" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">取消录制</button>
    </div>
  </div>
</template>

<style>
.video-container {
  width: 100%;
  max-width: 500px; /* Set the maximum width you want */
  overflow: hidden;
}
</style>

<script>
export default {
  data() {
    return {
      cameras: [],
      selectedCamera: null,
      videoStream: null,
      mediaRecorder: null,
      chunks: [],
      videoFile: null,
      videoURL: '',
      isRecording: false,
      hasRecorded: false
    };
  },
  emits: ['recorded'],
  methods: {
    async getCameras() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.cameras = devices.filter(device => device.kind === 'videoinput');
      if (this.cameras.length > 0) {
        this.selectedCamera = this.cameras[0].deviceId;
      }
    },
    async startCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
      }
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.selectedCamera } });
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error('Error accessing the camera', error);
      }
    },
    startRecording() {
      this.chunks = [];
      this.mediaRecorder = new MediaRecorder(this.videoStream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.chunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.chunks, { type: 'video/mp4' });
        this.videoFile = new File([blob], 'recordedVideo.mp4', { type: 'video/mp4' });
        this.videoURL = URL.createObjectURL(blob);
        this.hasRecorded = true;
        this.$emit('recorded', this.videoFile);
      };

      this.mediaRecorder.start();
      this.isRecording = true;
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    cancelRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.chunks = [];
        this.videoFile = null;
        this.videoURL = '';
        this.isRecording = false;
        this.hasRecorded = false;
      }
      this.startCamera()
    }
  },
  watch: {
    selectedCamera() {
      this.startCamera();
    }
  },
  mounted() {
    this.getCameras();
  }
};
</script>