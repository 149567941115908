<template>
  <div id="app" class="flex min-h-screen flex-col w-screen">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* Add your global styles here */
</style>