<template>
  <div class="bg-gray-200 min-h-screen flex flex-col justify-center items-center">
    <div class="bg-white rounded-lg shadow-lg p-8 max-w-screen-2xl">
      <WebCam ref="webCam" @recorded="saveVideoFile" />
      <hr class="my-2">
      <textarea name="input" id="input" rows="5"
        placeholder="获取文本后，文本将显示在此处"
        class="w-full rounded-lg p-4 bg-black/5 border-2 border-solid border-black/10 font-medium text-3xl resize-none" readonly>{{ currentText?.content }}</textarea>
      <div class="flex gap-4 justify-center p-4">
        <button v-if="currentText" @click="uploadVideo" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">上传视频</button>
        <button v-if="!currentText" @click="fetchText" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">获取文本</button>
        <button v-if="currentText" @click="skipText" class="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-lg">跳过文本</button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WebCam from "../components/WebCam.vue";
import { toast } from 'vue3-toastify';

export default {
  components: {WebCam},
  data: () => ({
    currentText: null,
    videoFile: null,
    webCamInstance: null,
  }),
  created() {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.webCamInstance = this.$refs.webCam;
  },
  methods: {
    fetchText() {
      this.videoFile = null;
      this.webCamInstance.cancelRecording();
      axios.get(import.meta.env.VITE_API_BASE_URL + 'api/texts/next/', {
        headers: {
          'Authorization': 'Token ' + sessionStorage.getItem('access_token')
        }
      })
      .then(response => {
        if (!response.data) {
            toast.warn('没有更多文本可获取');
            sessionStorage.setItem('text_id', null);
            this.currentText = null;
          return;
        }
        this.currentText = response.data;
        sessionStorage.setItem('text_id', this.currentText.id);
      })
      .catch(error => {
        toast.error('获取文本错误: ' + error.response.data.message);
      });
    },
    skipText() {
      if (!this.currentText) {
        toast.warn('没有要跳过的文本');
        return;
      }
      axios.post(import.meta.env.VITE_API_BASE_URL + 'api/texts/skip/', {
        text_id: this.currentText.id
      }, {
        headers: {
          'Authorization': 'Token ' + sessionStorage.getItem('access_token')
        }
      })
      .then(response => {
        this.fetchText();
      })
      .catch(error => {
        toast.error('跳过文本错误: ' + error.response.data.message);
      });
    },
    saveVideoFile(file) {
      this.videoFile = file;
    },
    uploadVideo() {
      const token = sessionStorage.getItem('access_token');
      const textId = sessionStorage.getItem('text_id');
      const file = this.videoFile;

      // Check if the file is a video and is not null
      if (!file) {
        toast.warn('请先录制一个视频。');
        return;
      }

      if (!file.type.startsWith('video/')) {
        toast.warn('请先上传一个视频文件');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('text_id', textId);

      axios.post(import.meta.env.VITE_API_BASE_URL + 'api/videos/upload/', formData, {
        headers: {
          'Authorization': 'Token ' + token,  // Assuming token-based auth
        },
        body: formData
      })
      .then(response => {
        toast.success('视频上传成功');
        this.fetchText();
      })
      .catch(error => {
        toast.error('上传视频错误: ' + error.response.data.message);
      });
    }
  }
}
</script>