<template>
  <RegisterWindow @submit="register" />
</template>

<script>
import axios from 'axios';
import RegisterWindow from '../components/RegisterWindow.vue'
import { toast } from 'vue3-toastify';

export default {
  components: {
    RegisterWindow
  },
  data: () => ({
    username: '',
    password: '',
    confirmPassword: ''
  }),
  methods: {
    register(credentials) {
      axios.post(import.meta.env.VITE_API_BASE_URL + 'api/users/register/', {
        username: credentials.username,
        password: credentials.password,
        confirmPassword: credentials.confirmPassword
      })
      .then(response => {
        sessionStorage.setItem('access_token', response.data.access_token);
        this.$router.push('/');
      })
      .catch(error => {
        toast.error('注册失败: ' + error.response.data.message);
      });
    }
  }
}
</script>